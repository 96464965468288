// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$efin-website-primary: mat-palette($mat-indigo);
$efin-website-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$efin-website-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$efin-website-theme: mat-light-theme(
  (
    color: (
      primary: $efin-website-primary,
      accent: $efin-website-accent,
      warn: $efin-website-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($efin-website-theme);

/* You can add global styles to this file, and also import other style files */
button:focus {
  outline: none;
}
.form-group {
  margin-bottom: 1.8rem;
}
.loading-item {
  li {
    height: 22px;
    list-style: none !important;
  }
}
.error {
  color: #ff6a6a;
  float: left;
  text-align: left;
}
.log_out_menu {
  width: auto;
  top: 70px !important;
  right: -27px !important;
}
.lock-item {
  position: relative;
  a:after {
    content: "" !important;
  }
  .fa-lock {
    font-size: 17px;
    color: #6c757de0;
    position: absolute;
    right: 15px;
    top: 22px;
  }
}
h4 {
  .fa-lock {
    font-size: 20px !important;
    color: #6c757de0;
  }
}
.master-access,
.student-access,
.tutor-access {
  .free-section {
    color: inherit;
  }
}
.chapter-locked {
  background: #e9ecef;
  .card-body {
    cursor: not-allowed;
  }
}
.default-section {
  cursor: pointer;
}
.free-section {
  color: #04a304 !important;
  font-weight: 500;
  cursor: pointer;
}
.default-section:hover,
.free-section:hover {
  border-bottom: 1px solid #2125296b;
}
.non-free {
  cursor: not-allowed !important;
}
.non-free:hover {
  border: none;
}
.section-disabled {
  cursor: not-allowed;
  a {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
.section-disabled.active {
  background-color: #f6f6f6 !important;
}
.section-disabled:hover {
  background-color: #f6f6f6 !important;
}
.search_btn {
  position: absolute;
  top: 10px;
  right: -58px;
  background-color: transparent;
  border: none;
}
button:disabled {
  background-color: #d8d8d8 !important;
}
.pagination-container {
  text-align: center;
}
.btnLoading {
  display: initial !important;
  position: absolute !important;
  margin-top: 0px;
  margin-left: 5px;
  z-index: 9;
}
.btnLoading.white circle {
  stroke: #fff;
}
.no-image-sm {
  width: 30px;
  height: 30px;
  font-size: 14px;
  padding: 0 !important;
  display: flex !important;
  background: #00cddb;
  position: fixed;
  justify-content: center;
  align-items: center;
  border: 1px solid #00cddb;
  text-transform: uppercase;
  border-radius: 88px;
  color: #213343;
  margin-top: -10px;
  text-align: center;
}
.not-found-page {
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 10%;
  padding-bottom: 7%;
  label {
    font-weight: 500;
  }
  .content-height {
    height: 20vh;
  }
}
.modal-custom {
  .body {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .modal-footer {
    padding: 5px !important;
  }
}

.snackbar-warning {
  background-color: #86592a;
}
.snackbar-error {
  background-color: #dc3545;
}
.snackbar-warning,
.snackbar-error {
  .mat-button-wrapper {
    color: #eee;
    font-weight: bold;
  }
}
.tag-input-custom {
  height: 44px;
  padding: 0 5px;
  tag {
    padding: 0px 10px !important;
    height: 32px !important;
    line-height: 34px !important;
  }
  .ng2-tag-input {
    padding: 0 !important;
    border-bottom: none !important;
  }
  .ng2-tag-input__text-input {
    height: 34px !important;
    min-width: 275px;
  }
  .ng2-tag-input.ng2-tag-input--focused {
    border: none;
    border-bottom: none !important;
    padding: 0 !important;
  }
  .ng2-tags-container {
    padding-top: 3px !important;
  }
  .ng2-menu-item {
    width: 100%;
  }
}
app-definition {
  .tab-content {
    min-height: 50vh;
  }
}

/*===========================================================
===================== Quill Rich Text Editor ================
=============================================================*/

.editor-wrapper.communication {
  border: 1px solid #cbcbcb85;
  border-radius: 15px;
  box-shadow: 0 1px 7px 0px #97979759;

  .quill-editor {
    border: none;
  }
  .ql-toolbar {
    margin: 0 20px;
    border-bottom: none;
    border-left: none;
    border-right: none;
  }
}
.quill-editor {
  height: 250px;
}
.ql-formats {
  button:focus {
    outline: none;
    color: initial;
  }
}
#quillAttachments {
  float: right;
  margin-right: 40px;
}
.default-html p {
  width: auto !important;
  text-align: inherit !important;
}
.cursor-pointer {
  cursor: pointer;
}
.name-lite {
  font-weight: 100 !important;
}
.inbox-thread {
  height: 23px !important;
  overflow: hidden !important;
  line-height: 20px !important;
}
span.member_icon img,
.group_ img {
  object-fit: cover;
}
h6.confirm_delete_txt {
  line-height: 24px;
}
/*======================== END ======================*/

.prev-loader {
  width: 100%;
  padding: 15px;
  text-align: center;
  .preview-loader {
    width: 30px !important;
    display: inline-block !important;
  }
}
.tutor-image {
  width: 80px;
  object-fit: cover;
}

.interactive-content {
  width: 100% !important;
  max-width: 100%;
  // overflow-x: scroll;
  .ng-select {
    width: 99% !important;
  }
  p {
    width: auto !important;
    text-align: left !important;
  }
  h3 {
    padding: inherit !important;
  }
  .output {
    // background-color: #e9f6ff;
    font-size: 14px;
    color: #232323;
    // padding: 10px;
    width: 100%;
  }
  .form-control {
    height: 34px;
    width: 99%;
  }
  label,
  img {
    // padding: 10px;
    // padding-left: 0;
    padding: 5px 10px !important;
  }
  table {
    // background: #f8f9fa;
    margin-bottom: 0;
    width: auto;
    td {
      padding: 0px;
      border: 1px solid #d8d8d8;
      // padding: 10px 10px 10px 0 !important;
      // padding-right: 10px;
      // vertical-align: middle !important;
    }
    .ng-select.single,
    .ng-select.multiple,
    .form-control {
      border: none !important;
      margin-bottom: 0 !important;
    }
  }

  video {
    // width: 100%;
    // height: 60vh;
    object-fit: contain;
    padding: 0;
  }
  iframe {
    width: 100%;
    height: 50vh;
  }
}

/*========================== Ng Select ============================*/
ng-select {
  padding: 10px 15px;
  border-radius: 3px;
  background: transparent;
  border: 1px solid #dbdbdb !important;
  box-shadow: none !important;
  .ng-select-container span.ng-arrow-wrapper span.ng-arrow {
    height: 11px;
    top: 3px;
    width: 16px;
    background-repeat: no-repeat;
  }
  .ng-select-container {
    cursor: pointer !important;
  }
  .ng-select-container .ng-value-container {
    margin-right: 10px;
  }
  .ng-dropdown-panel {
    left: 0;
    top: 34px;
    padding: 15px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid #f7f6fb !important;
    background: #fff;
    transition: all 0.2s linear;
    .ng-dropdown-panel-items .ng-option {
      padding: 6px 10px;
    }
    .ng-dropdown-panel-items .ng-option:hover {
      background: #5748b5;
      border-radius: 4px;
      color: #fff;
    }
  }
  .ng-arrow-wrapper {
    background: url("assets/img/Arrow-down.svg");
    background-size: 100% 50%;
    background-repeat: no-repeat;
    right: 0;
    top: 7px;
  }
}
ng-select.page-limit {
  padding: 8px 15px;
  /*border: 1px solid #ececec;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);*/
  .ng-select-container {
    width: 50px !important;
  }
  .ng-dropdown-panel-items .ng-option {
    text-align: center;
  }
}
ng-select.no-width {
  padding: 8px 15px;
  /*border: 1px solid #ececec;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);*/
  .ng-select-container {
    width: auto !important;
  }
  .ng-dropdown-panel-items .ng-option {
    text-align: left;
  }
}
ng-select.single,
ng-select.multiple {
  padding: 5px 10px !important;
  margin-bottom: 5px;
}
ng-select.single-f {
  height: 40px;
  min-width: 130px;
}
ng-select.single.with-image {
  .ng-dropdown-panel {
    top: 58px;
  }
  span.group_icon {
    width: 45px;
    height: 45px;
    margin-right: 10px;
    font-size: 22px;
  }
  .tutor-image {
    width: 45px !important;
    height: 45px !important;
  }
}
ng-select.multiple {
  .ng-select-container .ng-value-container .ng-value {
    white-space: nowrap;
    background: #f4f4f4;
    margin: 0 2px;
    padding: 0 2px;
    border-radius: 10px;
  }
  .ng-option[aria-selected="true"] {
    background: #f57d2033;
  }
}
ng-select.multiple .ng-select-container .ng-value-container .ng-value {
  color: #f4f4f4 !important;
  background-color: #0db2ba !important;
  padding: 3px 10px;
  margin: 2px 2px;
  font-size: 13px;
}
ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover {
  background: #0db2ba;
  color: #fff;
}
ng-select.multiple .ng-option[aria-selected="true"] {
  background: #26cfd8;
  color: #fff;
}
/*=============================================================*/

.file-upload-container {
  .ngx-file-drop__drop-zone {
    border: none !important;
  }
  .ngx-file-drop__content {
    color: inherit !important;
    height: 50px !important;
    font-size: 14px;
    cursor: pointer;
  }
  .dashed_inpt {
    height: 80px !important;
  }
  .image-preview-container {
    width: 150px;
    height: 150px;
    border-radius: 10px;
    box-shadow: -3px 4px 10px rgb(0 0 0 / 8%);
    position: relative;
    text-align: center;
    vertical-align: middle;
    margin-top: 1.2rem;
    margin-left: 17px;
    margin-right: 0 !important;
    .bar-close {
      position: absolute;
      right: 4px;
      top: 4px;
    }
    .image-preview {
      width: 120px;
      height: 100px;
      margin-top: 27px;
      object-fit: contain;
    }
    .preview-loader {
      position: absolute;
      right: 0;
      top: 23px;
    }
    img {
      max-width: 100%;
      width: 10px;
    }
  }
}
.file-upload-progress {
  margin: 10px 0;
}
/*======================================*/

.mat-tooltip-custom {
  background-color: #fff;
  margin: 0 !important;
  border: none;
  .fa {
    color: gray;
  }
}
.interactive-info-container {
  position: relative;
  .interactive-info {
      position: absolute;
      right: 0;
      top: 0;
  }
}
.interactive-info-container-1 {
  position: relative;
  .interactive-info {
      position: absolute;
      right: 0;
      top:9px;
  }
  .mat-tooltip-custom{
    background-color: #fff0;
  }
}
app-payment-success {
  .noResultsCont {
    margin-top: 20%;
  }
}

/*================================ D3Js ===============================*/
.d3-container {
  position: relative;
  .legends {
    position: absolute;
    right: 0;
    top: 46%;
    .item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
    .circle {
      width: 40px;
      margin-right: 5px;
    }
  }
}

/* ============================ MATHJAX =============================*/

.mjx-chtml {
  font-size: 100% !important;
}

/* ========================== Loader =============================*/

.loading-container {
  width: 100%;
  background: #213343db;
  display: inline-block;
  height: 100vh;
  position: fixed;
  z-index: 99;
}
.loading-wrapper {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 30%;
  left: 3%;
  h2 {
    color: #fff;
  }
  .logo {
    width: 300px;
    margin-top: 20px;
  }
}

.loader {
  text-align: center;
  justify-content: center;
  vertical-align: middle;
  position: relative;
  display: flex;
}

.loader span {
  display: block;
  width: 20px;
  height: 20px;
  background: #eee;
  border-radius: 50%;
  margin: 0 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}

.loader span:nth-child(2) {
  background: #6ea2f0;
}

.loader span:nth-child(3) {
  background: #84cdfa;
}

.loader span:nth-child(4) {
  background: #5ad1cd;
}

.loader span:not(:last-child) {
  animation: animate 1.5s linear infinite;
}

@keyframes animate {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(30px);
  }
}

.loader span:last-child {
  animation: jump 1.5s ease-in-out infinite;
}

@keyframes jump {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(10px, -10px);
  }
  20% {
    transform: translate(20px, 10px);
  }
  30% {
    transform: translate(30px, -50px);
  }
  70% {
    transform: translate(-150px, -50px);
  }
  80% {
    transform: translate(-140px, 10px);
  }
  90% {
    transform: translate(-130px, -10px);
  }
  100% {
    transform: translate(-120px, 0);
  }
}

/* =========================================================*/


.mat-xp-panel {
  box-shadow: none !important;
  margin-bottom: 0.75rem !important;
  .mat-expansion-panel-header {
      padding: 0;
      padding-right: 5px;
      height: auto;
      h5 {
          margin-bottom: 0;
      }
  }
  .mat-expansion-panel-header:hover {
      background: none !important;
  }
  .mat-expansion-panel-body {
      padding: 0 !important;
      margin-top: 5px !important;
  }
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #008b95;
}
app-definition{
  position: relative;
}
#tooltipDiv {
  position: absolute;
  display: none;
  background: #000;
  color: #fff;
  padding: 7px 10px;
  border-radius: 10px;
  font-size: 13px;
}
.exam-timer-wrp {
  width: 200px;
  display: grid;
  text-align: center;
  background: #00cddb;
  color: #fff;
  padding: 10px;
  line-height: 30px;
  border-radius: 10px;
  margin: 10px;
  position: fixed;
  right: 0px;
  bottom: 0;
  z-index: 999;
  countdown {
    font-size: 25px;
    font-weight: 600;
  }
  a {
    color: #0040ff;
    text-decoration: underline;
  }
}
.exam-disabled {
  pointer-events: none;
  background: #e5e5e5;
  padding: 10px;
  border-radius: 10px;
}
.certificate-wrp {
  padding: 3rem 17rem;
}

.divider {
  border-bottom: 1px solid #D8D8D8;
  width: 100%;
}